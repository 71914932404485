/**
* Generated theme by Material Theme Generator
* https://material-theme-generator.travetto.io
*/
@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

$fontConfig: (
	display-4: mat.define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
	display-3: mat.define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
	display-2: mat.define-typography-level(45px, 48px, 400, 'Roboto', 0em),
	display-1: mat.define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
	headline: mat.define-typography-level(24px, 32px, 400, 'Roboto', 0em),
	title: mat.define-typography-level(20px, 32px, 500, 'Roboto', 0.0075em),
	subheading-2: mat.define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
	subheading-1: mat.define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
	body-2: mat.define-typography-level(14px, 24px, 500, 'Roboto', 0.0179em),
	body-1: mat.define-typography-level(14px, 20px, 400, 'Roboto', 0.0179em),
	button: mat.define-typography-level(14px, 14px, 500, 'Roboto', 0.0893em),
	caption: mat.define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em),
	input: mat.define-typography-level(inherits, 1.125, 400, 'Roboto', 1.5px)
);

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba(rgba(black, 0.87), 0.54);
$dark-disabled-text: rgba(rgba(black, 0.87), 0.38);
$dark-dividers: rgba(rgba(black, 0.87), 0.12);
$dark-focused: rgba(rgba(black, 0.87), 0.12);

$light-theme-foreground-palette: (
	base: black,
	divider: rgba(black, 0.12),
	dividers: rgba(black, 0.12),
	disabled: rgba(black, 0.38),
	disabled-button: rgba($dark-text, 0.26),
	disabled-text: rgba(black, 0.38),
	elevation: black,
	secondary-text: $dark-accent-text,
	hint-text: rgba(black, 0.38),
	accent-text: $dark-accent-text,
	icon: $dark-accent-text,
	icons: $dark-accent-text,
	text: rgba(black, 0.87),
	slider-min: rgba(black, 0.87),
	slider-off: rgba($dark-text, 0.26),
	slider-off-active: rgba(black, 0.38)
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$dark-theme-foreground-palette: (
	base: $light-text,
	divider: rgba(white, 0.12),
	dividers: rgba(white, 0.12),
	disabled: rgba(white, 0.5),
	disabled-button: rgba($light-text, 0.3),
	disabled-text: rgba(white, 0.5),
	elevation: black,
	hint-text: rgba(white, 0.5),
	secondary-text: $light-accent-text,
	accent-text: $light-accent-text,
	icon: $light-text,
	icons: $light-text,
	text: $light-text,
	slider-min: $light-text,
	slider-off: rgba($light-text, 0.3),
	slider-off-active: rgba($light-text, 0.3)
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
	background: $light-background,
	status-bar: $light-bg-darker-20,
	app-bar: $light-bg-darker-5,
	hover: $dark-bg-alpha-4,
	card: $light-bg-lighter-5,
	dialog: $light-bg-lighter-5,
	disabled-button: $dark-bg-alpha-12,
	raised-button: $light-bg-lighter-5,
	focused-button: rgba(black, 0.12),
	selected-button: $light-bg-darker-20,
	selected-disabled-button: $light-bg-darker-30,
	disabled-button-toggle: $light-bg-darker-10,
	unselected-chip: $light-bg-darker-10,
	disabled-list-option: $light-bg-darker-10
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
	background: $dark-background,
	status-bar: $dark-bg-lighter-20,
	app-bar: $dark-bg-lighter-5,
	hover: $light-bg-alpha-4,
	card: $dark-bg-lighter-5,
	dialog: $dark-bg-lighter-5,
	disabled-button: $light-bg-alpha-12,
	raised-button: $dark-bg-lighter-5,
	focused-button: rgba(white, 0.12),
	selected-button: $dark-bg-lighter-20,
	selected-disabled-button: $dark-bg-lighter-30,
	disabled-button-toggle: $dark-bg-lighter-10,
	unselected-chip: $dark-bg-lighter-20,
	disabled-list-option: $dark-bg-lighter-10
);

// Compute font config
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($fontConfig);`
@include mat.all-legacy-component-typographies($fontConfig);
@include mat.legacy-core();

// Theme Config

$mat-primary: (
	main: #006b75,
	lighter: #b3d3d6,
	darker: #004e58,
	200: #006b75,
	// For slide toggle,
		contrast:
		(
			main: white,
			lighter: rgba(black, 0.87),
			darker: white
		)
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

$mat-accent: (
	main: #009aa8,
	lighter: #b3e1e5,
	darker: #007e8d,
	200: #009aa8,
	// For slide toggle,
		contrast:
		(
			main: white,
			lighter: rgba(black, 0.87),
			darker: white
		)
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

$mat-warn: (
	main: #a80e00,
	lighter: #e5b7b3,
	darker: #8d0800,
	200: #a80e00,
	// For slide toggle,
		contrast:
		(
			main: white,
			lighter: rgba(black, 0.87),
			darker: white
		)
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat.define-dark-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include mat.all-legacy-component-themes($theme);

.theme-alternate {
	@include mat.all-legacy-component-themes($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
