.date-range {
  display: inline-flex;
  align-items: center;

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  .date-arrows {
    display: flex;
    cursor: pointer;
  }

  .date-selector {
    position: relative;
    display: inline-block;
  }

  .start-date {
    margin-right: 24px;
  }

  input {
    width: 157px;
    display: inline-block;
  }
}

.cavo-date-picker {
	display: flex;
	margin: 0 6px;
}

.cavo-mat-icon {
	&.material-icons {
		color: #757A8E;
	}

	.instance-card & {
		height: 18px;
		width: 18px;
		font-size: 18px;
	}
}
