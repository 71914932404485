.date-range {
  display: inline-flex;
  align-items: center;
}

.cavo-mat-datepicker {
  &.mat-form-field-appearance-outline .mat-form-field-infix {
    display: flex;
    justify-content: space-between;
  }
}

.cavo-outline-input.mat-form-field-appearance-outline:not(.search-filter-input) {
  .mat-form-field-label {
    margin-top: -17px !important;
  }
}
.cavo-outline-input.search-filter-input.mat-form-field-appearance-outline {
  .mat-form-field-label {
    margin-top: -14px !important;
  }
}

.cavo-outline-input {
  &.mat-form-field {
    font-size: 16px;
    letter-spacing: 0.34px;
    width: 100%;

    &.mat-focused {
      .mat-form-field-outline-thick,
      .mat-form-field-label,
      .mat-input-element {
        color: rgba($primaryDarkBlue, 1) !important;
      }
    }

    &.mat-form-field-appearance-outline .mat-icon {
      color: #757a8e !important;
    }
  }

  &.mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: rgba($cloudyBlue, 1) !important;
    }

    .mat-form-field-outline-thick {
      color: rgba($primaryDarkBlue, 0.8) !important;

      .mat-form-field-outline-end,
      .mat-form-field-outline-gap,
      .mat-form-field-outline-start {
        border-width: 1px !important;
      }
    }

    .mat-form-field-infix {
      padding: 0 0 6px 0;
    }

    .mat-form-field-label {
      color: rgba($charcoalBlack, 1) !important;
    }

    &.mat-form-field-should-float .mat-form-field-label {
      margin-top: -4px !important;
      font-size: 12px;
    }

    .mat-input-element {
      color: rgba($primaryDarkBlue, 1) !important;
      height: unset !important;
    }

    .mat-form-field-suffix {
      top: 0.4em;
    }

    .mat-icon {
      color: rgba($primaryDarkBlue, 1) !important;
    }

    &.mat-form-field-disabled {
      .mat-input-element {
        color: rgba($primaryDarkBlue, 0.5) !important;
      }
    }
  }

  &.mat-with-error {
    .mat-form-field-subscript-wrapper {
      position: relative !important;
      margin-top: 6px !important;
      padding: 0 !important;
      letter-spacing: normal;
      line-height: normal;
    }
  }
  &.reset-error {
    .mat-form-field-subscript-wrapper {
      top: 4px;
      margin: 0 !important;
      padding-left: 12px !important;
    }
  }

  &.mat-form-field-invalid {
    .mat-form-field-outline,
    .mat-form-field-outline-thick,
    .mat-form-field-label {
      color: $accentDeepRed !important;
    }

    &.mat-form-field.mat-focused {
      .mat-form-field-outline,
      .mat-form-field-outline-thick,
      .mat-form-field-label {
        color: $accentDeepRed !important;
      }
    }

    .mat-form-field-flex:hover {
      .mat-form-field-label {
        color: $accentDeepRed !important;
      }
    }
  }
}
