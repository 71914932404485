$darkTeal: #2684a6;
$teal: #6fbed5;
$ltTeal: #c2e3ed;
$blue: #337ab7;

$ltBlue: #daeef5;
$oldDarkBlue: #1a5874;
$darkerBlue: #003748;

// Blue border colors
$borderBlue: #2e6da4;
$borderDkBlue: #204d74;

$green: #2c9646;
$success: #526713;
$lightGreen: rgba(0, 154, 168, 1); // #009AA8
$darkGreen: rgba(0, 107, 117, 1); // #006B75

// Reds
$coral: #f05245;
$darkCoral: #c34237;
$brightOrange: #ff4800;

// Blacks & Grays
$oldGray: #ccc;
$tableHeader: #ebebeb;
$borderGray: #e5e5e5;

// highlight colors
$highlightYellow: #fff651;
$highlightBlack: #000;
$highlightRed: #e25d5d;
$highlightBlue: #8deaf8;

// Font
$fontFamily: 'Roboto', sans-serif;
$monoFamily: 'Roboto Mono', monospace;

// Font Sizes
$fontSizeH1: 36px;
$fontSizeH2: 28px;
$fontSizeH3: 24px;
$fontSizeBody: 17px;
$fontSizeSmall: 15px;
$fontSizeHead: 14px;
$fontSizeXSmall: 13px;
$fontSizeSubHead: 12px;
$fontSizeTiny: 11px;

// Navbar heights
$appHeaderHeight: 56px;

// renamed old variables for common colours

// New Colour Pallete
$aqua: rgb(0, 224, 245); // #00E0F5
$lightBlue: rgb(0, 73, 118); // #004976
$darkBlue: rgb(0, 37, 59); // #00253B
$twilightBlue: rgb(240, 251, 255); // #F0FBFF

$forestGreen: rgb(0, 129, 75); // #00814B
$hoverGreen: rgb(0, 141, 154); // #008D9A;

$darkerGreen: rgb(0, 131, 143); // #00838F

$black: rgb(0, 0, 0); // #000000
$gray: rgb(102, 102, 102); // #666666
$darkGray: rgb(51, 51, 51); // #333333
$lightGray: rgb(238, 238, 238); // #EEEEEE
$offWhite: rgb(243, 243, 243); // #F3F3F3
$newOffWhite: rgb(247, 247, 247); // #F7F7F7
$silver: rgb(171, 171, 171); // #ABABAB;
$alto: rgb(209, 209, 209); // #D1D1D1
$white: rgb(255, 255, 255); //#FFFFFF
$backGroundWhite: #f7f7f7;

$darkYellow: rgb(236, 226, 41); // #ECE229
$darkBrightRed: rgb(226, 93, 93); // #e25d5d
$brightRed: rgb(153, 0, 0); // #990000;
$brown: rgb(168, 98, 0); // #A86200

$blueHoverColor: rgb(0, 61, 98); //#003D62

$defaultShadowColor: rgba($darkGray, 0.5);
$defaultShadow: 0 2px 4px $defaultShadowColor;

// placeholders
$placeholderColor: #bababa;

$fontSizeLargeButton: 18px;

// Material colors

/*Primary colors*/
$primaryBlue: rgb(0, 73, 118); // #004976
$primaryLightBlue: rgb(68, 116, 165); //#4474A5
$primaryDarkBlue: rgb(0, 34, 74); //#00224A

/*Secondary colors*/
$charcoalBlack: rgb(117, 122, 142); //#757a8e
$cloudyBlue: rgb(181, 185, 202); //#b5b9ca
$secondaryGreen: rgb(0, 141, 154); // #008D9A;
$secondaryDarkGreen: rgb(0, 107, 117); // #006B75
$secondaryLightGreen: rgb(0, 154, 168); // #009AA8

/* Accent colors*/
$accentBlue: rgb(93, 195, 255); //#5DC3FF
$accentGreen: rgb(0, 129, 75); //#00814B
$accentPink: rgb(226, 94, 94); //#e25d5d
$accentDeepRed: rgb(153, 0, 0); //#990000
$accentBrightRed: rgb(243, 108, 36); //#f36c24
$hoverStar: rgb(255,226,157);// #ffe29d;
$accentPinkRed: rgb(167, 2, 58); //#a7023a
$accentYellow: rgb(255, 246, 81); //#fff651
$starYellow: rgb(255,207,92);// #ffcf5c;
$accentBrightBlue: rgb(9, 225, 246); //#09e1f6
$ghostBlue: rgb(229, 252, 255); //#e7fdff

