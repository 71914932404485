@import 'variables';
@import 'mixins';

* {
	box-sizing: border-box;
}


html,
body {
  font-family: $fontFamily;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  background: $primaryBlue;
}

.full-width {
  width: 100%;
}

.ml-auto {
  margin-left: auto;
}
