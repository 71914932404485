/* You can add global styles to this file, and also import other style files */
@import 'styles/cavo-theme.scss';

@import 'styles/font';
@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/functions';
@import 'styles/common';
@import 'styles/date-range';
@import 'styles/buttons';
@import 'styles/materials/material';

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.mat-dialog-title {
  padding: 16px 24px !important;
  background-color: #006b75;
  color: #fff;
}

.mat-dialog-container {
  padding: 0 !important;
}

.capitalize {
  text-transform: capitalize;
}

.label-tight-fit {
  margin: 0;
}

.mb-med {
  margin-bottom: 15px;
}

.mb-lg {
  margin-bottom: 25px;
}

.mat-form-field-wrapper {
  padding: 0;
}

.mt-25 {
  margin-top: 25px;
}

button + button {
  margin-left: 15px !important;
}

.mat-card-header {
  &.is-full {
    .mat-card-header-text {
      flex: 1;
    }
  }
}

:not(.stats-card) {
  &.mat-card {
    padding: 30px !important;
  }

  .mat-card-header-text {
    margin: 0 !important;

    .mat-card-title {
      margin-bottom: 20px;
    }
  }
}
